import React from 'react';
import ReactDOM from 'react-dom';
import './Bottom.css';
import logoIcon from '../Img/larin-icon.svg';
import marketIcon from '../Img/market-icon.svg';
import messagesIcon from '../Img/messages-icon.svg';
import favoritesIcon from '../Img/favorites-icon.svg';
import menuIcon from '../Img/menu-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Header from '../Header/Header';
import Home from '../Main/Home';
import Market from '../Main/Market';
import Search from '../Main/Search';
import Messages from '../Main/Messages';
import Menu from '../Main/Menu';
import {
	Link
  } from "react-router-dom";

class Bottom extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			activeIndexRouter: props.activeIndexRouter,
		};
	}
	
	render () {
		return(
			<div id="main-bottom">
				<div className="bottom">
					<div className="nav-bottom">
						<Container>
							<Row>
								<Col className={"col-home " + (this.state.activeIndexRouter === 'menu' ? "dnone" : "")}>
									<Link to={"/larin"}>
										<button className={'btn-nav-bottom btn-home ' + ((this.state.activeIndexRouter == null) || this.state.activeIndexRouter === 'home' ? "active" : "")}>
											<i className="menu-icon icon-larin-icon"></i>
										</button>
										<p>Larin</p>
									</Link>
								</Col>
								<Col className={"col-home " + ((this.state.activeIndexRouter != 'menu') ? "dnone" : "")}>
									<div className='logo-bottom'>
										<i className="menu-icon icon-larin-icon"></i>
									</div>
								</Col>
								<Col className={"col-market " + (this.state.activeIndexRouter === 'menu' ? "hidden" : "")}>
									<Link to={"/search"}>
										<button className={'btn-nav-bottom btn-market ' + (this.state.activeIndexRouter === 'search' ? "active" : "")}>
											<i className="menu-icon icon-search-icon"></i>
										</button>
										<p>Pesquisa</p>
									</Link>
								</Col>
								<Col className={"col-messages " + (this.state.activeIndexRouter === 'menu' || localStorage.getItem('userName') == null || localStorage.getItem('userName') == '' ? "hidden" : "")}>
									<Link to={"/messages"}>
										<button className={'btn-nav-bottom btn-messages ' + (this.state.activeIndexRouter === 'messages' ? "active" : "")}>
											<i className="menu-icon icon-message-nav-icon"></i>
										</button>
										<p>Mensagens</p>
									</Link>
								</Col>
								<Col className={"col-favorites " + (this.state.activeIndexRouter === 'menu' || localStorage.getItem('userName') == null || localStorage.getItem('userName') == '' ? "hidden" : "")}>
									<Link to={"/messages"}>
										<button className={'btn-nav-bottom btn-favorites ' + (this.state.activeIndexRouter === 'favourites' ? "active" : "")}>
											<i className="menu-icon icon-heart-icon"></i>
										</button>
										<p>Favoritos</p>
									</Link>
								</Col>
								<Col className={"col-menu " + (this.state.activeIndexRouter === 'menu' ? "dnone" : "")}>
									<Link to={"/menu"}>
										<button className={'btn-nav-bottom btn-menu ' + (this.state.activeIndexRouter === 'menu' ? "active" : "")} id="btn-menu">
											<i className="menu-icon icon-menu-icon"></i>
										</button>
										<p>Menu</p>
									</Link>
								</Col>
								<Col className={"col-close " + ((this.state.activeIndexRouter != 'menu') ? "dnone" : "")}>
									<Link to={-1}>
										<button className='btn-nav-bottom btn-close-menu' id="btn-close-menu">
											<i className="menu-icon icon-close-btt"></i>
										</button>
										<p>Close</p>
									</Link>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default Bottom;


